import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider, CorezoidLightTheme } from 'mw-style-react';
import smoothscroll from 'smoothscroll-polyfill';
// import {withPrefix} from 'gatsby-link';
import Header from '../Header';
import Footer from '../Footer';
import Utils from '../../utils/utils';
import '../../styles/core.scss';
import '../../styles/theme.scss';
import './Layout.scss';
// import { languages } from '../../../src/i18n/locales';
import titlesAndDescription from '../../constants/titlesAndDescription';
import mainLogo from '../../images/corezoid-logo-2.png';
// import { logo } from '../../images';

const actorId = process.env.WIDGET_ACTOR_ID;


class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
      firstRender: true,
      firstRenderCH: true
    };

    this.refLayout = null;
  }
  url = '';

  componentDidMount() {
    smoothscroll.polyfill();
    if (typeof window !== 'undefined') {
      window.__forceSmoothScrollPolyfill__ = true;
      window.scrollTo({
        top: 0
      });

      this.url = window.location.href;
      if (location.href.indexOf('/ru/')) {
        const url = location.href.replace('/ru/', '/');
        Utils.addTag('link',
          {
            rel: 'alternate',
            href: location.href,
            hreflang: 'ru'
          }
        );
        Utils.addTag('link',
          {
            rel: 'alternate',
            href: url,
            hreflang: 'en'
          }
        );
      }
    }
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      // if (!window.VWO) {
      //   Utils.addTag('script', {innerText: `
      //     var _vwo_code=(function(){
      //     var account_id=416452,
      //     settings_tolerance=2000,
      //     library_tolerance=2500,
      //     use_existing_jquery=false,
      //     /* DO NOT EDIT BELOW THIS LINE */
      //     f=false,d=document;return{use_existing_jquery:function(){return use_existing_jquery;},
      //     library_tolerance:function(){return library_tolerance;},finish:function(){if(!f)
      //     {f=true;var a=d.getElementById('_vis_opt_path_hides');
      //     if(a)a.parentNode.removeChild(a);}},
      //     finished:function(){return f;},load:function(a){var b=d.createElement('script');
      //     b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};
      //     d.getElementsByTagName('head')[0].appendChild(b);},init:function()
      //     {settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);
      //     var a=d.createElement('style'),b='body{opacity:0 !important;
      //     filter:alpha(opacity=0) !important;
      //     background:none !important;}',h=d.getElementsByTagName('head')[0];
      //     a.setAttribute('id','_vis_opt_path_hides');
      //     a.setAttribute('type','text/css');
      //     if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));
      //     h.appendChild(a);this.load('//dev.visualwebsiteoptimizer.com/j.php?
      //     a='+account_id+'&u='+encodeURIComponent(d.URL)+'&r='+Math.random());
      //     return settings_timer;}};}());_vwo_settings_timer=_vwo_code.init();
      //   `});
      // }
      // if (!window.firebase) {
      //   Utils.addTag('script', {
      //     type: 'text/javascript',
      //     src: '//www.gstatic.com/firebasejs/3.6.8/firebase.js'
      //   });
      //   Utils.addTag('script', {
      //     type: 'text/javascript',
      //     src: '/firebase_subscribe.js'
      //   });
      // }
      if (!window.gtag) {
        Utils.addTag('script', {innerText: 'src="https://www.googletagmanager.com/gtag/js?id=UA-100140652-4"'});
        Utils.addTag('script', {innerText: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-100140652-4');
        `});
      }

      if (!window.ga) {
        Utils.addTag('script', {innerText: `
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', 'UA-100140652-4', 'auto');
          ga('send', 'pageview');
        `});
      } else {
        window.ga('send', 'pageview');
      }

      if (!window.google_tag_manager) {
        Utils.addTag('script', {innerText:
          `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WMM4VZW');`
        });
        Utils.addTag('iframe', {
          src: 'https://www.googletagmanager.com/ns.html?id=GTM-WMM4VZW',
          height: '0',
          width: '0',
          style: 'display:none;visibility:hidden'
        });
      }

      if (!window.hj) {
        Utils.addTag('script', {
          innerText: `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2128548,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `
        });
      }
    }
  }

  componentWillUnmount() {
    Utils.removeTags({
      tag: 'link',
      rel: 'alternate'
    });
  }

  _handleScroll() {
    const { isScrolled, firstRender } = this.state;
    if (this.refLayout.scrollTop > 0 && !isScrolled) {
      this.setState({
        isScrolled: true
      });
    } else if (this.refLayout.scrollTop === 0 && isScrolled) {
      this.setState({
        isScrolled: false
      });
    }

    // виджет sender
    if ((!window.ctrlSettings || !window.ctrl) && firstRender) {
      Utils.addTag('script', {
        innerText: `
        window.ctrlSettings = {webWidget: {actorId: "${actorId}"}};
      `
      });
      Utils.addTag('script', {
        innerText: `
        (function () {
          window.ctrl = window.ctrl || function () {
            (window.ctrl.q = window.ctrl.q || []).push(arguments);
          };
          var script = document.createElement("script");
          var parent = document.getElementsByTagName("script")[0].parentNode;
          script.async = 1;
          script.src = "https://widget.control.events/shim.js";
          parent.appendChild(script);
        })();
      `
      });
      this.setState({
        firstRender: false
      });
    }

    // виджет hypercomments
    if (this.props.pageContext && this.props.pageContext.originalPath === '/faq/') {
      const position = document.getElementById('hypercomments_widget').offsetTop;
      if (this.state.firstRenderCH && this.refLayout.scrollTop > (position - 1000)) {
        const script = `_hcwp = window._hcwp || [];
        _hcwp.push({widget:"Stream", widget_id: 106047});
        (function() {
        if("HC_LOAD_INIT" in window)return;
        HC_LOAD_INIT = true;
        var lang = (navigator.language || navigator.systemLanguage ||
           navigator.userLanguage ||  "en").substr(0, 2).toLowerCase();
        var hcc = document.createElement("script");
           hcc.type = "text/javascript"; hcc.async = true;
        hcc.src = ("https:" == document.location.protocol ? "https" : "http")+"://w.hypercomments.com/widget/hc/106047/"+lang+"/widget.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hcc, s.nextSibling);
        })();`;
        this.setState({
          firstRenderCH: false
        });
        setTimeout(() => {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.innerText = script;
          document.getElementsByTagName('body')[0].appendChild(s);
        }, 0);
      }
    }
  }

  render() {
    const { page, children, location = {}, intl } = this.props;
    const { pathname, href } = location;
    const { isScrolled } = this.state;
    const lacation = this.props.pageContext && this.props.pageContext.originalPath;
    const mainHeader = lacation === '/';
    const locale = intl && intl.locale || 'en';

    const metadata = Utils.getDataWithFallback(titlesAndDescription, pathname);

    return (
      <ThemeProvider theme={CorezoidLightTheme}>
        <div
          onScroll={this._handleScroll.bind(this)}
        >
          <div className="layout__header">
            <Header
              page={page}
              isScrolled={isScrolled}
              mainHeader={mainHeader && !isScrolled}
            />
          </div>
          <div
            className="layout"
            ref={el => (this.refLayout = el)}
          >
            <Helmet>
              <html lang={locale} />
              <meta property="og:image" content={`https://corezoid.com${mainLogo}`} />
              <meta property="og:title" content={metadata.title} />
              <meta property="og:description" content={metadata.description} />
              <meta property="og:url" content={href} />
              <meta property="og:type" content="website" />
              <meta property="og:site_name" content="Corezoid" />
              <title>
                {metadata.title}
              </title>
              <meta
                name="msvalidate.01"
                content="0797F619DCE97D327363AE48DFF5820A"
              />
              <meta
                name="description"
                content={metadata.description}
              />
              <script src="https://www.gstatic.com/firebasejs/7.2.3/firebase-app.js" />
              {process.env.GATSBY_BUILD_ENV === 'production' && (
                <meta
                  name="google-site-verification"
                  content="MqXV4BaLGUW4FwNBRc91D_5-RFZfoh3fwaZz9OVUdPE"
                />
              )}
            </Helmet>
            <div className="layout__content">
               {children}
            </div>
            {/* <Link className="layout__online" to={'https://whereby.com/corezoid'}>*/}
            {/*   <div className="layout__online__btn"><Image file={'video-icon.png'}/></div>*/}
            {/*   <div className="layout__online__text">Corezoid Cafe</div>*/}
            {/* </Link>*/}
            <div className="layout__footer">
              <Footer/>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

Layout.defaultProps = {
  page: '',
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  pageContext: PropTypes.object,
  page: PropTypes.string,
  location: PropTypes.object,
  intl: PropTypes.object
};

export default Layout;
